<template>
  <section>
    <label class="item-label">{{ label }}</label>
    <br />
    <div v-if="audioFileLocation">
      <audio controls class="audio-player" :src="audioFileLocation">
        <source :src="src" type="audio/mp3" />
      </audio>
    </div>

    <div class="d-flex align-center justify-space-between mb-4">
      <label
        :title="uiTitle"
        class="es-btn small mr-3"
        :class="{ ghost: status !== 'Draft' }"
      >
        Upload a new audio file
        <input
          v-if="status === 'Draft'"
          class="d-none"
          type="file"
          accept=".mp3, audio/mp3"
          @change="upload($event, 'audio')"
          @click="clearFileInput"
        />
        <validation-provider rules="required">
          <input type="text" v-model="audioFileLocation" class="d-none" />
        </validation-provider>
      </label>
      <v-chip
        outlined
        v-if="audioFileLocation"
        :title="audioFileLocation"
        :href="audioFileLocation"
        target="_blank"
      >
        🎵 Open audio in new tab
      </v-chip>
    </div>

    <div class="audiofile-meta">
      <div v-if="audioFileSize">
        <span v-if="audioFileSize > 1024" class="icon-invalid">✘</span>
        <span v-else class="icon-valid">✔</span>
        File size: {{ audioFileSize }}kB
        <span class="explanation" v-if="audioFileSize > 1024"
          >The file should be less than 1024kB in size.</span
        >
      </div>
      <div v-if="audioFileFormat">
        <span v-if="!isMp3" class="icon-invalid">✘</span>
        <span v-else class="icon-valid">✔</span>
        Format: '{{ audioFileFormat }}'
        <span class="explanation" v-if="!isMp3"
          >The file should be in 'MP3' format.</span
        >
      </div>
      <div v-if="audioFileNumberOfChannels">
        <span v-if="audioFileNumberOfChannels !== 1" class="icon-invalid"
          >✘</span
        >
        <span v-else class="icon-valid">✔</span>
        Channels: {{ audioFileNumberOfChannels }}
        <span class="explanation" v-if="audioFileNumberOfChannels !== 1"
          >The file should be 'mono' (only one channel).</span
        >
      </div>
      <div v-if="audioFileSampleRate">
        <span v-if="audioFileSampleRate !== 44100" class="icon-invalid">✘</span>
        <span v-else class="icon-valid">✔</span>
        Sample rate:
        {{ new Intl.NumberFormat("en").format(audioFileSampleRate) }}Hz
        <span class="explanation" v-if="audioFileSampleRate !== 44100"
          >The sampling frequency should be 44,100Hz.</span
        >
      </div>
      <div v-if="audioFileBitRate">
        <span v-if="audioFileBitRate < 16" class="icon-invalid">✘</span>
        <span v-else class="icon-valid">✔</span>
        Bit rate: {{ new Intl.NumberFormat("en").format(audioFileBitRate) }}Kbps
        <span class="explanation" v-if="audioFileBitRate < 16"
          >The bit rate should be more than 16Kbps.</span
        >
      </div>
    </div>
  </section>
</template>

<script>

import MediaInfo from "mediainfo.js";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    label: String,
    status: String,
    audioFilename: String,
    audioFileLocation: String,
  },
  data: () => ({
    audioFileSize: null,
    audioFileFormat: null,
    audioFileNumberOfChannels: null,
    audioFileSampleRate: null,
    audioFileBitRate: null,
  }),
  computed: {
    ...mapState("account", ["user"]),
    isMp3() {
      return this.audioFileFormat.toLowerCase().includes("mpeg");
    },
    uiTitle() {
      return this.status === "Draft" ? false : "Upload is only available for Status: Draft."
    },
    src: {
      get() { return this.audioFileLocation },
      set(value) { this.$emit("update:audioFileLocation", value) },
    },
  },
  methods: {
    ...mapActions("item", ["uploadFile"]),
    clearFileInput() {
      this.audioFileSize = null
      this.audioFileFormat = null
      this.audioFileNumberOfChannels = null
      this.audioFileSampleRate = null
      this.audioFileBitRate = null
      this.src = null
    },
    async upload(event) {
      /**
       * Find out details about the uploaded audio file.
       * @see: https://mediainfo.js.org
       */
      try {
        const reader = new FileReader();
        const file = event.target.files[0];
        reader.readAsArrayBuffer(file);
        reader.addEventListener("load", async () => {
          const mediaInfo = await MediaInfo();
          const { media } = await mediaInfo.analyzeData(
            () => file.size,
            () => new Uint8Array(reader.result)
          );
          const audioData = media.track.find((row) => row["@type"] === "Audio");
          if (audioData) {
            let audioIsInValid = false
            this.audioFileSize = Math.floor(
              audioData.StreamSize / Math.pow(1024, 1)
            );
            this.audioFileFormat = audioData.Format;
            this.audioFileNumberOfChannels = Number(audioData.Channels);
            this.audioFileSampleRate = Number(audioData.SamplingRate);
            this.audioFileBitRate = Number(audioData.BitRate) / 1000;
            if(this.audioFileNumberOfChannels !== 1){
              audioIsInValid = true
            }
            if(this.audioFileSampleRate !== 44100){
              audioIsInValid = true
            }
            if(this.audioFileBitRate < 16){
              audioIsInValid = true
            }
            if(!this.isMp3 ){
              audioIsInValid = true
            }
            if(this.audioFileSize > 1024){
              audioIsInValid = true
            }
            this.$store.commit('item/setAudioIsInvalid', audioIsInValid)
          }
        });
      } catch (error) {
        console.log(error);
      }

      /**
       * Upload the audio file to the server
       */

      // Set audioFileLocation to null to make the audio player reload the uploaded audio file
      this.src = null;

      try {
        const formData = new FormData();
        formData.append("author_id", this.user.user_id);
        formData.append("file", event.target.files[0]);
        formData.append("content_type", "audio/mpeg");

        const {
          data: { file_name, file_location },
        } = await this.uploadFile(formData);
        this.src = file_location;
        this.$emit("update:audioFilename", file_name);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audiofile-meta {
  margin: 10px 0 30px 0;
  font-size: small;
  & .explanation {
    color: rgb(38, 77, 152);
  }
  & .icon-invalid {
    color: red;
  }
  & .icon-valid {
    color: green;
  }
}

.audio-player {
  border-radius: 10px;
  width: 100%;
}

label {
  color: #264d98;
  font-size: 1em;
  margin-bottom: 10pt 0pt;
  &.ghost {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(230, 230, 230, 0.4);
    border: none;
  }
}
</style>